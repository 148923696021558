import { DatePicker, Input } from 'antd';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

function getMonthData(record, month, title) {
  return {
    monthTotal: record['montantTotal' + month],
    monthElements: record?.elements?.[month] || [],
    dragPrefix: 'category=' + record.id + '&month=' + month + '&levelId=' + record.levelId + '&index=' + record.index + '&ParentId=' + record.parentId,
    indexMultiplier: record.id,
    categoryName: record.name,
    title: title,
    synthese: record.synthese,
    objectives: record.objectives,
    objectif: record.objectif,
    categories: record
  };
}

function getMonthDataElemnts(record, month, title, value) {
  return {
    monthTotal: record['montantTotal' + month],
    monthElements: record[month],
    dragPrefix: `month=${month}${record[month] ? '&elementId=' + record[month]?.id : ''}&index=${record.index}`,
    indexMultiplier: record.index,
    categoryName: record.name,
    title: title,
    synthese: record.synthese,
    objectives: record.objectives,
    objectif: record.objectif,
    categories: record
  };
}

function getDefaultColumns(renderCell, title, renderAction, dateFilter, checkedSynthese, handleChangeDate, checkedObjectif, SelectedObjective, addObjective) {
  const monthsArray = dateFilter?.monthArray;
  let arrayCoulnms = [
    {
      title: title,
      dataIndex: 'name',
      width: monthsArray?.length < 3 ? 60 : 200,
      fixed: 'left',
      className: 'ColoumnHeaderTitle',
      render: (value, record) => renderCell(getMonthData(record, 'CATEGORY', title))
    }
  ];

  let objectif = {
    title: () => {
      if (SelectedObjective) {
        return <span> {SelectedObjective?.nameObjective}</span>;
      } else {
        return <Input onPressEnter={addObjective} />;
      }
    },
    dataIndex: 'objectif',
    fixed: 'left',
    width: monthsArray?.length < 3 ? 60 : 200,
    editable: true,
    className: 'customerObjectif',
    render: (value, record) => renderCell(getMonthData(record, 'OBJECTIF', title))
  };
  let synthese = {
    title: () => {
      return (
        <div className="synthseTable">
          <span>Synthése</span>
          <div className="rangePickerSynthese">
            <RangePicker
              className="rangePicker"
              picker="month"
              allowClear={false}
              onChange={handleChangeDate}
              defaultValue={[dayjs(dateFilter.startDateSynthese, 'YYYY-MM-DD'), dayjs(dateFilter.endDateSynthese, 'YYYY-MM-DD')]}
            />
          </div>
        </div>
      );
    },
    dataIndex: 'synthese',
    fixed: 'left',
    width: monthsArray?.length < 3 ? 60 : 200,
    className: 'customerSynthese',
    render: (value, record) => renderCell(getMonthData(record, 'SYNTHESE', title))
  };
  if (checkedObjectif) {
    arrayCoulnms.push(objectif);
  }
  if (checkedSynthese) {
    arrayCoulnms.push(synthese);
  }
  const dataFilterArray = () => {
    monthsArray?.forEach((month) => {
      let object = {
        title: `${month}`,
        dataIndex: '',
        width: 150,
        className: title === 'Trésorerie' ? 'customerTableTreso' : 'customerTableCompta',
        render: (value, record) => renderCell(getMonthData(record, `${month}`, title))
      };
      arrayCoulnms.push(object);
    });
  };
  dataFilterArray();
  return [
    ...arrayCoulnms,
    {
      title: '',
      dataIndex: 'delete',
      width: monthsArray?.length < 3 ? '10px' : '30px',
      className: 'ant-table-cell ant-table-cell-fix-right ant-table-cell-fix-right-last action',
      render: (value, record) => renderAction(record)
    }
  ];
}

function getDefaultColumnsElements(renderCell, title, dateFilter, mode) {
  const monthsArray = dateFilter?.monthArray;
  let arrayCoulnms = [
    {
      title: mode === 'treso' ? 'Trésorerie' : 'Comptabilité',
      dataIndex: 'name',
      width: '200px',
      fixed: 'left',
      className: 'ColoumnHeaderTitle'
    }
  ];
  const dataFilterArray = () => {
    monthsArray?.forEach((month) => {
      let object = {
        title: `${month}`,
        dataIndex: '',
        width: '150px',
        className: mode === 'treso' || mode === undefined ? 'customerTableTreso' : 'customerTableCompta',
        render: (value, record) => renderCell(getMonthDataElemnts(record, `${month}`, title, value))
      };
      arrayCoulnms.push(object);
    });
  };
  dataFilterArray();
  return [...arrayCoulnms];
}

function nestCategories(data, parentId = null) {
  return data?.reduce((r, e) => {
    if (parentId === e.parentId) {
      const children = nestCategories(data, e.id);
      const object = {
        ...e
      };
      if (children.length) {
        object.children = children;
      }
      r.push(object);
    }
    return r;
  }, []);
}

function getQueryStringParams(query) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
}

function checkDragAndDropParent(id, object) {}

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export { getDefaultColumns, nestCategories, MONTHS, getQueryStringParams, getDefaultColumnsElements };
