import { toast } from 'react-toastify';
import AuthService from '../../../services/AuthService';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  VERIF_ACCOUNT_SUCCESS,
  SET_MESSAGE,
  VERIF_ACCOUNT_FAIL
} from './type';

export const requestLogin = (creds) => async (dispatch) => {
  try {
    const res = await AuthService.login(creds);
    let data = res.data;
    dispatch({
      type: LOGIN_REQUEST,
      isFetching: true,
      isAuthenticated: false
      // creds: creds
    });
    if (res.success === true) {
      dispatch(receiveLogin(data));
    }
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;
    toast.error('Email ou mot de passe invalide');
    dispatch(loginError(message));
    return Promise.resolve(error);
  }
};
export const receiveLogin = (user) => async (dispatch) => {
  localStorage.setItem('access_token', user.token);
  localStorage.setItem('user', JSON.stringify(user.user));
  dispatch({
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    access_token: user.token
  });
};
export const loginError = (message) => async (dispatch) => {
  dispatch({
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  });
};
//SignUp

export const requestSignUp = (user) => async (dispatch) => {
  try {
    const res = await AuthService.create(user);
    let data = res.data;
    dispatch({
      type: SIGNUP_REQUEST,
      isFetching: true,
      isAuthenticated: false
      // creds: creds
    });
    if (res.success === true) {
      dispatch(receiveSignUp(data));
      toast.info('Veuillez confirmer votre compte par Email');
    }
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;
    dispatch(SignUpError(message));
    toast.error("L'email est déjà utilisé!");
    return Promise.resolve(error);
  }
};
export const receiveSignUp = (user) => async (dispatch) => {
  dispatch({
    type: SIGNUP_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user: user
  });
};
export const SignUpError = (message) => async (dispatch) => {
  dispatch({
    type: SIGNUP_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  });
};

export const verifAccount = (data) => (dispatch) => {
  return AuthService.verifyAccount(data).then(
    (response) => {
      dispatch({
        type: VERIF_ACCOUNT_SUCCESS,
        verif: 'true'
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: response.message,
          status: response.status
        }
      });
      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      dispatch({
        type: VERIF_ACCOUNT_FAIL
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: message,
          status: error.status
        }
      });

      return Promise.reject();
    }
  );
};
