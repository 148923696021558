import { Select } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTableElement from '../../components/CustomTableElement/CustomTableElement';
import { getonlyElements } from '../../redux/actions/element';
import './Categories.scss';
const Categories = ({ title, classStyle }) => {
  const userId = JSON.parse(localStorage.getItem('user')).id;
  const mode = useSelector((state) => state.categories.mode);
  const dateFilter = useSelector((state) => state.categories.dateFilter);

  const [statut, setStatut] = React.useState();
  const [valeur, setValeur] = React.useState();

  React.useEffect(() => {
    if (mode === 'treso') {
      dispatch(getonlyElements(userId, 'dateTreso', statut, valeur, dateFilter?.startDate, dateFilter?.endDate));
    } else if (mode === 'compta') {
      dispatch(getonlyElements(userId, 'dateCompta', statut, valeur, dateFilter?.startDate, dateFilter?.endDate));
    }
  }, [statut, valeur, dateFilter]);

  const dispatch = useDispatch();
  const options = [
    { label: 'Prévisionnel', value: 1 },
    { label: 'Engage', value: 2 },
    { label: 'Pointe', value: 3 }
  ];
  const optionsCompta = [
    { label: 'Simulation', value: 'Simulation' },
    { label: 'Relle', value: 'Relle' }
  ];
  const optionsValue = [
    { label: 'Croissant', value: 'croissant' },
    { label: 'Décroissant', value: 'decroissant' }
  ];
  const handleChangeStatut = (value) => {
    setStatut(value);
  };
  const handleChangeValeur = (value) => {
    setValeur(value);
  };
  return (
    <div>
      <div className="filterElements">
        <div>
          <Select
            defaultValue={'Filter Par statut'}
            style={{
              width: 180
            }}
            onChange={handleChangeStatut}
            options={
              mode === 'treso'
                ? options.map((item) => ({
                    value: item.value,
                    label: item.label
                  }))
                : optionsCompta.map((item) => ({
                    value: item.value,
                    label: item.label
                  }))
            }
            allowClear={true}
          />
        </div>
        <div>
          <Select
            defaultValue="Filter Par Valeur"
            style={{
              width: 180
            }}
            onChange={handleChangeValeur}
            options={optionsValue.map((item) => ({
              value: item.value,
              label: item.label
            }))}
            allowClear={true}
          />
        </div>
      </div>
      <CustomTableElement title={title} classStyle={classStyle} />{' '}
    </div>
  );
};

export default Categories;
