import http from './http-common';

const create = (data, title, startDate, endDate, startDateSynthese, endDateSynthese) => {
  const params = {};
  if (title) {
    params.title = title;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  params.startDateSynthese = startDateSynthese;
  params.endDateSynthese = endDateSynthese;
  return http.post('/elements', data, { params: params }).then((response) => {
    return response.data;
  });
};

const deleteElement = (elementsId, title, startDate, endDate, month, startDateSynthese, endDateSynthese) => {
  const params = {};
  if (title) {
    params.title = title;
  }
  if (month !== undefined) {
    params.month = month;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  params.startDateSynthese = startDateSynthese;
  params.endDateSynthese = endDateSynthese;
  return http.delete(`/elements/${elementsId}`, { params: params }).then((response) => {
    return response;
  });
};

const update = (id, data, title, startDate, endDate, month, startDateSynthese, endDateSynthese) => {
  const params = {};
  if (title) {
    params.title = title;
  }
  if (month !== undefined) {
    params.month = month;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  params.startDateSynthese = startDateSynthese;
  params.endDateSynthese = endDateSynthese;

  return http.put(`/elements/${id}`, data, { params: params }).then((response) => {
    return response.data;
  });
};
const updateElementDragAndDrop = (id, data, mode) => {
  const params = {};
  if (mode) {
    params.mode = mode;
  }
  return http.put(`/elements/dragAndDrop/${id}`, data, { params: params }).then((response) => {
    return response.data;
  });
};
const get = (id) => {
  return http.get(`/elements/${id}`).then((response) => {
    return response.data;
  });
};

const getLastId = () => {
  return http.get(`/elements/last/id`).then((response) => {
    return response.data;
  });
};

const getonlyElements = (userId, mode, statut, valeur, startDate, endDate, filterSolde) => {
  let params = {};
  params.mode = mode;
  params.statut = statut;
  params.valeur = valeur;
  params.startDate = startDate;
  params.endDate = endDate;
  if (filterSolde) {
    params.filterSolde = filterSolde;
  }

  return http.get(`/elements/onlyelements/${userId}`, { params: params }).then((response) => {
    return response.data;
  });
};
export default {
  create,
  deleteElement,
  update,
  updateElementDragAndDrop,
  get,
  getLastId,
  getonlyElements
};
