export const CREATE_CATEGORIE_SUCCESS = 'CREATE_CATEGORIE_SUCCESS';
export const CREATE_CATEGORIE_FAIL = 'CREATE_CATEGORIE_FAIL';
export const CREATE_OBJECTIVE_CATEGORIE_SUCCESS = 'CREATE_OBJECTIVE_CATEGORIE_SUCCESS';
export const CREATE_OBJECTIVE_CATEGORIE_FAIL = 'CREATE_OBJECTIVE_CATEGORIE_FAIL';

export const LIST_CATEGORIE_SUCCESS = 'LIST_CATEGORIE_SUCCESS';
export const LIST_CATEGORIE_FAIL = 'LIST_CATEGORIE_FAIL';
export const LIST_CATEGORIE_All = 'LIST_CATEGORIE_All';

export const DELETE_CATEGORIE_SUCCESS = 'DELETE_CATEGORIE_SUCCESS';
export const DELETE_CATEGORIE_FAIL = 'DELETE_CATEGORIE_FAIL';

export const GET_CATEGORIE_SUCCESS = 'GET_CATEGORIE_SUCCESS';
export const GET_CATEGORIE_FAIL = 'GET_CATEGORIE_FAIL';

export const LIST_CATEGORIE_SUCCESS_FINAL = 'LIST_CATEGORIE_SUCCESS_FINAL';
export const LIST_ELEMENTS_SUCCESS = 'LIST_ELEMENTS_SUCCESS';

export const UPDATE_CATEGORIE_SUCCESS = 'UPDATE_CATEGORIE_SUCCESS';
export const UPDATE_CATEGORIE_FAIL = 'UPDATE_CATEGORIE_FAIL';
export const UPDATE_OBJECTIVE_CATEGORIE_SUCCESS = 'UPDATE_OBJECTIVE_CATEGORIE_SUCCESS';
export const UPDATE_OBJECTIVE_CATEGORIE_FAIL = 'UPDATE_OBJECTIVE_CATEGORIE_FAIL';

export const EDITING_ACTION = 'EDITING_ACTION';
export const COLOR_SOLDE_ACTION = 'COLOR_SOLDE_ACTION';
export const FILTER_DATE_ACTION = 'FILTER_DATE_ACTION';
export const LIST_CATEGORIE_COMPACT = 'LIST_CATEGORIE_COMPACT';
export const LIST_CATEGORIE_COMPACT_FAIL = 'LIST_CATEGORIE_COMPACT_FAIL';
export const LIST_CATEGORIE_COMPACT_STATUT = 'LIST_CATEGORIE_COMPACT_STATUT';

export const LOADING_TOGGLE_ACTION = 'LOADING_TOGGLE_ACTION';
export const MONTH_FILTER_SUCCESS = 'MONTH_FILTER_SUCCESS';
export const CHECKED_SYNTHESE_SUCCESS = 'CHECKED_SYNTHESE_SUCCESS';
export const CHECKED_OBJECTIF_SUCCESS = 'CHECKED_OBJECTIF_SUCCESS';
export const SELECTED_OBJECTIF_SUCCESS = 'SELECTED_OBJECTIF_SUCCESS';
export const EDITING_OBJECTIVE_ACTION = 'EDITING_OBJECTIVE_ACTION';
export const SELECTED_MODE_SUCCESS = 'SELECTED_MODE_SUCCESS';
export const SELECTED_CHART_SUCCESS = 'SELECTED_CHART_SUCCESS';
export const UPDATE_CATEGORIE_MULTIPLE_SUCCESS = 'UPDATE_CATEGORIE_MULTIPLE_SUCCESS';
export const UPDATE_CATEGORIE_MULTIPLE_FAIL = 'UPDATE_CATEGORIE_MULTIPLE_FAIL';
export const CREATE_TOGGLE_SUCCESS = 'CREATE_TOGGLE_SUCCESS';
