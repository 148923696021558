import http from './http-common';

const create = (data) => {
  return http.post('/categories', data).then((response) => {
    return response.data;
  });
};
const get = (id) => {
  return http.get(`/categories/${id}`).then((response) => {
    return response.data;
  });
};

const listTreso = (startDate, endDate, month, startDateSynthese, endDateSynthese, userId, SelectedObjective) => {
  const params = {};
  params.startDate = startDate;
  params.endDate = endDate;
  params.startDateSynthese = startDateSynthese;
  params.endDateSynthese = endDateSynthese;
  if (month !== undefined) {
    params.month = month;
  }
  if (SelectedObjective) {
    params.SelectedObjective = SelectedObjective;
  }
  return http.get(`/categories/all/treso/${userId}`, { params: params }).then((response) => {
    return response.data;
  });
};

const listCompta = (startDate, endDate, month, startDateSynthese, endDateSynthese, userId, SelectedObjective) => {
  const params = {};
  params.startDate = startDate;
  params.endDate = endDate;
  params.startDateSynthese = startDateSynthese;
  params.endDateSynthese = endDateSynthese;
  if (month !== undefined) {
    params.month = month;
  }
  if (SelectedObjective) {
    params.SelectedObjective = SelectedObjective;
  }
  return http.get(`/categories/all/compta/${userId}`, { params: params }).then((response) => {
    return response.data;
  });
};
const listSoldeTrseo = (soldeFilter, startDate, endDate, month, startDateSynthese, endDateSynthese, userId, SelectedObjective) => {
  const params = {};
  if (soldeFilter) {
    params.soldeFilter = soldeFilter;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  params.startDateSynthese = startDateSynthese;
  params.endDateSynthese = endDateSynthese;
  if (month !== undefined) {
    params.month = month;
  }
  if (SelectedObjective) {
    params.SelectedObjective = SelectedObjective;
  }
  return http
    .get(`/categories/all/treso/${userId}`, {
      params: params
    })
    .then((response) => {
      return response.data;
    });
};

const listSoldeCompta = (soldeFilter, startDate, endDate, month, startDateSynthese, endDateSynthese, userId, SelectedObjective) => {
  const params = {};
  if (soldeFilter) {
    params.soldeFilter = soldeFilter;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  params.startDateSynthese = startDateSynthese;
  params.endDateSynthese = endDateSynthese;
  if (month !== undefined) {
    params.month = month;
  }
  if (SelectedObjective) {
    params.SelectedObjective = SelectedObjective;
  }
  return http
    .get(`/categories/all/compta/${userId}`, {
      params: params
    })
    .then((response) => {
      return response.data;
    });
};

const listCategoriePopup = (userId) => {
  return http.get(`/categories/all/cat/${userId}`).then((response) => {
    return response.data;
  });
};

const update = (id, data, startDate, endDate, month, startDateSynthese, endDateSynthese) => {
  let params = {};
  if (month !== undefined) {
    params.month = month;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  params.startDateSynthese = startDateSynthese;
  params.endDateSynthese = endDateSynthese;
  return http.put(`/categories/${id}`, data, { params: params }).then((response) => {
    return response.data;
  });
};

const updateMultiple = (userId, data, startDate, endDate, month, startDateSynthese, endDateSynthese) => {
  let params = {};
  if (month !== undefined) {
    params.month = month;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  params.startDateSynthese = startDateSynthese;
  params.endDateSynthese = endDateSynthese;
  return http.put(`/categories/multiple/${userId}`, data, { params: params }).then((response) => {
    return response.data;
  });
};

const deleteCategorie = (categorieId, soldeFilter, startDate, endDate) => {
  const params = {};
  if (soldeFilter) {
    params.soldeFilter = soldeFilter;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  return http
    .delete(`/categories/${categorieId}`, {
      params: params
    })
    .then((response) => {
      return response;
    });
};

const deleteCategorieOne = (userId, data, soldeFilter, startDate, endDate, startDateSynthese, endDateSynthese) => {
  const params = {};
  if (soldeFilter) {
    params.soldeFilter = soldeFilter;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  params.startDateSynthese = startDateSynthese;
  params.endDateSynthese = endDateSynthese;
  return http.put(`/categories/deleteOne/${userId}`, data, { params: params }).then((response) => {
    return response.data;
  });
};

const compactCatgeory = (title, soldeFilter, startDate, endDate, month) => {
  const params = {};
  if (soldeFilter) {
    params.soldeFilter = soldeFilter;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  params.month = month;
  params.title = title;
  return http
    .get('/categories/all/compact', {
      params: params
    })
    .then((response) => {
      return response.data;
    });
};

const createObjectiveWithCategory = (data) => {
  return http.post('/categories/createObjectiveCategory', data).then((response) => {
    return response.data;
  });
};

const updateObjectiveWithCategory = (id, data, title, startDate, endDate, startDateSynthese, endDateSynthese) => {
  const params = {};
  if (title) {
    params.title = title;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  params.startDateSynthese = startDateSynthese;
  params.endDateSynthese = endDateSynthese;
  return http.put(`/objectives/createObjectiveCategory/${id}`, data, { params: params }).then((response) => {
    return response.data;
  });
};
export default {
  get,
  create,
  listTreso,
  update,
  updateMultiple,
  deleteCategorie,
  deleteCategorieOne,
  listCategoriePopup,
  listSoldeTrseo,
  listSoldeCompta,
  listCompta,
  compactCatgeory,
  createObjectiveWithCategory,
  updateObjectiveWithCategory
};
