import React from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import isLoggedIn from '../helpers/isLoggedIn';
import Home from '../pages/Home/Home';
import Signup from '../pages/Auth/signup/signup';
import Signin from '../pages/Auth/signin/signin';
import ConfirmEmail from '../pages/Auth/ConfirmEmail/confirmEmail';
import ForgetPassword from '../pages/Auth/forgetPassword/forgetPassword';
import ChangePassword from '../pages/Auth/changePassword/changePassword';
import CustomTable from '../components/CustomTable/CustomTable';
import Settings from '../pages/Settings/Settings';
import Notifications from '../pages/Notifications/Notifications';
import Dashboard from '../pages/Dashboard/Dashboard';
import Categories from '../pages/Categories/Categories';
import SendEmailForgetPassword from '../pages/Auth/sendEmailForgetPassword/sendEmailForgetPassword';

const AppRouter = () => {
  let protectedRoutes = useRoutes([
    {
      path: '/',
      element: (
        <Home>
          <CustomTable title={'Trésorerie'} classStyle={'customerTableTreso'} />
        </Home>
      ),
      exact: true
    },
    {
      path: '/table-compta',
      element: (
        <Home>
          <CustomTable title={'Comptabilité'} classStyle={'customerTableCompta'} />
        </Home>
      ),
      exact: true
    },
    {
      path: '/Categories',
      element: (
        <Home>
          <Categories title={'Trésorerie'} classStyle={'customerTableTreso'} />
        </Home>
      ),
      exact: true
    },
    {
      path: '/Categories/Compta',
      element: (
        <Home>
          <Categories title={'Comptabilité'} classStyle={'customerTableCompta'} />
        </Home>
      ),
      exact: true
    },
    {
      path: '/chart',
      element: (
        <Home>
          <Dashboard />
        </Home>
      ),
      exact: true
    },
    {
      path: '/notif',
      element: (
        <Home>
          <Notifications />
        </Home>
      ),
      exact: true
    },
    {
      path: '/settings',
      element: (
        <Home>
          <Settings />
        </Home>
      ),
      exact: true
    }
  ]);
  let publicRoutes = useRoutes([
    {
      path: '/',
      element: <Signin />,
      exact: true
    },
    {
      path: '/login',
      element: <Signin />,
      exact: true
    },
    {
      path: '/signup',
      element: <Signup />,
      exact: true
    },
    {
      path: '/confirm/:confirmation_token',
      element: <ConfirmEmail />,
      exact: true
    },
    {
      path: '/forgetPassword',
      element: <ForgetPassword />,
      exact: true
    },
    {
      path: '/changePassword',
      element: <ChangePassword />,
      exact: true
    },
    {
      path: '/sendEmail',
      element: <SendEmailForgetPassword />,
      exact: true
    }
  ]);
  if (isLoggedIn()) {
    return protectedRoutes;
  } else {
    return publicRoutes;
  }
};

function RouterComponent() {
  return (
    <Router>
      <AppRouter />
    </Router>
  );
}

export default RouterComponent;
