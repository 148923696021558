import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { chekDate } from '../../helpers/checkDate';
import { getQueryStringParams } from '../../helpers/helpers';
import { createToogle } from '../../redux/actions/categories';
import { updateElement, updateElementDragAndDrop } from '../../redux/actions/element';

const DragandDropElement = ({ ...props }) => {
  const mode = useSelector((state) => state.categories.mode);
  const toogleCreate = useSelector((state) => state.categories.toogleCreate);

  const userId = JSON.parse(localStorage.getItem('user')).id;
  const dispatch = useDispatch();

  const { children, title } = props;

  const onDragEnd = async (result) => {
    const { elementId: draggedElement } = getQueryStringParams(result.draggableId);
    const { month: droppedOnMonth } = getQueryStringParams(result.destination?.droppableId || '');

  
    if (mode === 'compta') {
      const updateData = {
        dateCompta: chekDate(droppedOnMonth),
        userId: userId
      };
      await dispatch(updateElementDragAndDrop(draggedElement, updateData, 'dateCompta'));
    } else if (mode === 'treso') {
      const updateData = {
        dateTreso: chekDate(droppedOnMonth),
        userId: userId
      };
      await dispatch(updateElementDragAndDrop(draggedElement, updateData, 'dateTreso'));
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <tbody>{children}</tbody>
    </DragDropContext>
  );
};

export default DragandDropElement;
