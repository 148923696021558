import {
  CREATE_Element_SUCCESS,
  CREATE_Element_FAIL,
  DELETE_Element_SUCCESS,
  DELETE_Element_FAIL,
  UPDATE_Element_SUCCESS,
  UPDATE_Element_FAIL,
  GET_ElementS_SUCCESS,
  GET_ElementS_FAIL,
  GET_Element_ID,
  GET_ONLYELEMENT_SUCCESS,
  GET_ONLYELEMENT_FAIL
} from './type';

import { LIST_CATEGORIE_SUCCESS_FINAL, LIST_ELEMENTS_SUCCESS, SELECTED_MODE_SUCCESS, SELECTED_CHART_SUCCESS } from '../categories/type';
import ElementService from '../../../services/ElementService';
import CategorieService from '../../../services/CategorieService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadingToggleAction } from '../categories';

export const createElement = (data, title, startDate, endDate, startDateSynthese, endDateSynthese, userId) => async (dispatch) => {
  try {
    const [res] = await Promise.all([
      // CategorieService.listTreso(startDate, endDate, '', startDateSynthese, endDateSynthese, userId),
      // CategorieService.listCompta(startDate, endDate, '', startDateSynthese, endDateSynthese, userId),
      await ElementService.create(data, title, startDate, endDate, startDateSynthese, endDateSynthese)
    ]);

    dispatch({
      type: CREATE_Element_SUCCESS,
      payload: res.data.copie
    });
    dispatch({
      type: LIST_ELEMENTS_SUCCESS,
      payload: res.data.elements
    });
    return Promise.resolve(res.data);
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;

    dispatch({
      type: CREATE_Element_FAIL,
      payload: message
    });

    toast.error(' vous devez terminer la saisie ');

    return Promise.resolve(error);
  }
};

export const createElementbydate = (data, title, startDate, endDate, startDateSynthese, endDateSynthese) => async (dispatch) => {
  try {
    const res = await ElementService.create(data, title, startDate, endDate, startDateSynthese, endDateSynthese);

    const idElement = res.data.id;

    dispatch({
      type: CREATE_Element_SUCCESS,
      payload: res.data.copie
    });

    dispatch({
      type: LIST_ELEMENTS_SUCCESS,
      payload: res.data.elements
    });

    /* dispatch({
      type: GET_Element_ID,
      payload: idElement
    }); */
    return Promise.resolve(res.data);
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;

    dispatch({
      type: CREATE_Element_FAIL,
      payload: error
    });

    toast.error(' vous devez terminer la saisie ');
    return Promise.resolve(error);
  }
};

export const getElement = (id) => (dispatch) => {
  return ElementService.get(id).then(
    (response) => {
      dispatch({
        type: GET_ElementS_SUCCESS,
        payload: response.data
      });
      dispatch(loadingToggleAction(false));

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      dispatch({
        type: GET_ElementS_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const getonlyElements = (userId, mode, statut, valeur, startDate, endDate, filterSolde) => (dispatch) => {
  return ElementService.getonlyElements(userId, mode, statut, valeur, startDate, endDate, filterSolde).then(
    (response) => {
      dispatch({
        type: GET_ONLYELEMENT_SUCCESS,
        payload: response.data.objects
      });
      dispatch({
        type: LIST_CATEGORIE_SUCCESS_FINAL,
        payload: response.data.totaux
      });
      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      dispatch({
        type: GET_ONLYELEMENT_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const deleteElement = (id, title, startDate, endDate, month, startDateSynthese, endDateSynthese) => (dispatch) => {
  return ElementService.deleteElement(id, title, startDate, endDate, month, startDateSynthese, endDateSynthese).then(
    (response) => {
      dispatch({
        type: DELETE_Element_SUCCESS,
        payload: response.data.data.copie
      });

      dispatch({
        type: LIST_CATEGORIE_SUCCESS_FINAL,
        payload: response.data.data.totalSoldesMonth
      });

      dispatch({
        type: LIST_ELEMENTS_SUCCESS,
        payload: response.data.data.elements
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      dispatch({
        type: DELETE_Element_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const updateElement = (id, data, title, startDate, endDate, month, startDateSynthese, endDateSynthese) => (dispatch) => {
  return ElementService.update(id, data, title, startDate, endDate, month, startDateSynthese, endDateSynthese).then(
    (response) => {
      dispatch({
        type: UPDATE_Element_SUCCESS,
        payload: response.data.copie
      });

      dispatch({
        type: LIST_ELEMENTS_SUCCESS,
        payload: response.data.elements
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      dispatch({
        type: UPDATE_Element_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const updateElementDragAndDrop = (id, data, mode) => (dispatch) => {
  return ElementService.updateElementDragAndDrop(id, data, mode).then(
    (response) => {
      dispatch({
        type: GET_ONLYELEMENT_SUCCESS,
        payload: response.data.objects
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      dispatch({
        type: UPDATE_Element_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const modeElement = (value) => (dispatch) => {
  dispatch({
    type: SELECTED_MODE_SUCCESS,
    payload: value
  });
};

export const modeChart = (value) => (dispatch) => {
  dispatch({
    type: SELECTED_CHART_SUCCESS,
    payload: value
  });
};
