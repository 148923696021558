import { Button, Input, Popconfirm, Popover, Tooltip } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createToogle, listCategorie, listSolde } from '../../../redux/actions/categories';
import { deleteElement, updateElement } from '../../../redux/actions/element';
import ajoutElement from '../../../assets/icon/ajoutElement.svg';
import gear from '../../../assets/icon/Gear.svg';
import trash from '../../../assets/icon/Trash.svg';
import reel from '../../../assets/icon/reel.svg';
import simulation from '../../../assets/icon/simulation.svg';
import edit from '../../../assets/icon/edit.svg';
import prev from '../../../assets/icon/prev.svg';
import engage from '../../../assets/icon/engage.svg';
import pointe from '../../../assets/icon/pointe.svg';
import './cellElement.css';
import PopupCustomer from '../../PopupCustomer/PopupCustomer';
import { chekDate } from '../../../helpers/checkDate';
import { getQueryStringParams } from '../../../helpers/helpers';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Draggable, Droppable } from 'react-beautiful-dnd';

const CellElement = ({ monthTotal, monthElements, dragPrefix, indexMultiplier, categoryName, mode }) => {
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const monthFilter = useSelector((state) => state.categories.monthFilter);
  const toogleCreate = useSelector((state) => state.categories.toogleCreate);
  const elements = useSelector((state) => state.categories.elements);
  const userId = JSON.parse(localStorage.getItem('user')).id;
  const [editElement, setEditElement] = React.useState({ status: false });
  const [hover, setHover] = React.useState(false);
  const [hoverId, setHoverId] = React.useState(null);
  const [typeElement, setTypeElement] = React.useState('');
  const [isPopverVisible, setIsPopverVisible] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [listingElement, setListingElement] = React.useState(false);
  const [elementUpdate, setElementUpdate] = React.useState('');
  const [categoryElementName, setCategoryyElementName] = React.useState('');
  const [updateElementPop, setUpdateElementPop] = React.useState(false);
  const [months, setMonths] = React.useState([
    'January 2023',
    'February 2023',
    'March 2023',
    'April 2023',
    'May 2023',
    'June 2023',
    'July 2023',
    'August 2023',
    'September 2023',
    'October 2023',
    'November 2023',
    'December 2023'
  ]);
  const dispatch = useDispatch();

  const { month } = getQueryStringParams(dragPrefix);

  const toggleEditElement = (e) => {
    setEditElement({ status: true, id: e });
  };

  const handleUpdateEelementMontant = async (e, key) => {
    const data = {
      montant: e.target.value,
      userId: userId
    };
    if (key) {
      if (mode === 'treso') {
        await dispatch(
          updateElement(
            key,
            data,
            'Trésorerie',
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilter,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese
          )
        );
        await dispatch(
          listSolde('treso', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
      } else if (mode === 'compta') {
        await dispatch(
          updateElement(
            key,
            data,
            'Comptabilité',
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilter,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese
          )
        );
        await dispatch(
          listSolde('compta', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
      }
      dispatch(createToogle(!toogleCreate));
    }
  };

  const handleUpdateEelementLibelle = async (e, key) => {
    const data = {
      libelle: e.target.value,
      userId: userId
    };
    if (key) {
      if (mode === 'treso') {
        await dispatch(
          updateElement(
            key,
            data,
            'Trésorerie',
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilter,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese
          )
        );
        await dispatch(
          listSolde('treso', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
      } else if (mode === 'compta') {
        await dispatch(
          updateElement(
            key,
            data,
            'Comptabilité',
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilter,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese
          )
        );
        await dispatch(
          listSolde('compta', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
      }
      dispatch(createToogle(!toogleCreate));
    }
  };

  // Add Element Type Relle
  const addElementRelle = async () => {
    setTypeElement('Relle');
    setIsPopverVisible(false);
    setIsModalVisible(true);
    setListingElement(true);
  };

  // Add Element Type Relle
  const addElementSimulation = async () => {
    setTypeElement('Simulation');
    setIsPopverVisible(false);
    setIsModalVisible(true);
    setListingElement(true);
  };

  const content = () => {
    return (
      <div className="popup-ajouterEcriture">
        <Button
          className="simulation"
          value={'simulation'}
          onClick={() => {
            addElementSimulation();
          }}>
          DE SIMULATION
        </Button>
        <Button
          className="relle"
          value={'relle'}
          onClick={() => {
            addElementRelle();
          }}>
          RELLE
        </Button>
      </div>
    );
  };
  const changeStatut = async (idElement, statut) => {
    if (mode == 'compta') {
      await dispatch(
        updateElement(
          idElement,
          { type: statut, userId: userId },
          'Comptabilité',
          dateFilter.startDate,
          dateFilter.endDate,
          monthFilter,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese
        )
      );
      await dispatch(
        listCategorie('compta', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
      await dispatch(
        listSolde('compta', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
    } else if (mode == 'treso') {
      if (statut === 'PRÉVISIONNEL') {
        await dispatch(
          updateElement(
            idElement,
            { statutId: 1, userId: userId },
            'Trésorerie',
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilter,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese
          )
        );
      } else if (statut === 'ENGAGÉ') {
        await dispatch(
          updateElement(
            idElement,
            { statutId: 2, userId: userId },
            'Trésorerie',
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilter,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese
          )
        );
      } else if (statut === 'POINTÉ') {
        await dispatch(
          updateElement(
            idElement,
            { statutId: 3, userId: userId },
            'Trésorerie',
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilter,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese
          )
        );
      }
    }
    dispatch(createToogle(!toogleCreate));
  };

  // Function Delete Element
  const handleDeleteElement = async (idElement) => {
    if (mode === 'treso') {
      await dispatch(
        deleteElement(idElement, 'Trésorerie', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese)
      );
      dispatch(createToogle(!toogleCreate));
    } else if (mode === 'compta') {
      await dispatch(
        deleteElement(
          idElement,
          'Comptabilité',
          dateFilter.startDate,
          dateFilter.endDate,
          monthFilter,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese
        )
      );
      dispatch(createToogle(!toogleCreate));
    }
  };

  const findElement = async (idElement) => {
    const result = await elements?.filter((el) => {
      return el.id === idElement;
    });
    await setElementUpdate(result);
  };

  const updateElementGraphicsMode = async (idElement) => {
    await findElement(idElement);
    await setUpdateElementPop(true);
    setIsModalVisible(true);
  };

  const contentPopover = (idElement, typeElement, statut) => {
    if (mode === 'compta') {
      return (
        <div className="contentPopver">
          {statut === 1 && (
            <div className="contentStatut">
              <Tooltip title="RELLE">
                <img src={reel} onClick={() => changeStatut(idElement, 'Relle')} />
              </Tooltip>
              <Tooltip title="SIMULATION">
                <img src={simulation} onClick={() => changeStatut(idElement, 'Simulation')} />
              </Tooltip>
            </div>
          )}
          <div className="contentDelete">
            <Tooltip title="Supprimer">
              <div className="contentDelete__delete">
                <Popconfirm
                  placement="bottomRight"
                  title="Êtes-vous sûr？"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: 'red'
                      }}
                    />
                  }
                  onConfirm={() => handleDeleteElement(idElement)}>
                  <div className="btdelete">
                    <img src={trash} />
                  </div>
                </Popconfirm>
              </div>
            </Tooltip>
          </div>
          <div className="btdelete">
            <Tooltip title="Modifier">
              <button className="btedit" onClick={() => updateElementGraphicsMode(idElement)}>
                <img src={edit} />
              </button>
            </Tooltip>
          </div>
        </div>
      );
    } else if (mode === 'treso') {
      return (
        <div className="contentPopver">
          {typeElement === 'Relle' && (
            <div className="contentStatut">
              <Tooltip title="PRÉVISIONNEL">
                <img src={prev} onClick={() => changeStatut(idElement, 'PRÉVISIONNEL')} />
              </Tooltip>
              <Tooltip title="ENGAGÉ">
                <img src={engage} onClick={() => changeStatut(idElement, 'ENGAGÉ')} />
              </Tooltip>
              <Tooltip title="POINTÉ">
                <img src={pointe} onClick={() => changeStatut(idElement, 'POINTÉ')} />
              </Tooltip>
            </div>
          )}
          <div className="contentDelete">
            <Tooltip title="Supprimer">
              <div className="contentDelete__delete">
                <Popconfirm
                  placement="bottomRight"
                  title="Êtes-vous sûr？"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: 'red'
                      }}
                    />
                  }
                  onConfirm={() => handleDeleteElement(idElement)}>
                  <div className="btdelete">
                    <img src={trash} />
                  </div>
                </Popconfirm>
              </div>
            </Tooltip>
          </div>
          <div className="btdelete">
            <Tooltip title="Modifier">
              <button className="btedit" onClick={() => updateElementGraphicsMode(idElement)}>
                <img src={edit} />
              </button>
            </Tooltip>
          </div>
        </div>
      );
    }
  };

  const handleMouseEnter = (e) => {
    setHoverId(e.target.id);
    setHover(true);
  };
  const handleMouseLeave = async () => {
    setHoverId(null);
    setHover(false);
  };

  React.useEffect(() => {
    var elementstd = document.querySelectorAll('.elementCell');
    const tdCell = document.getElementsByClassName('table-td')[1].offsetWidth;

    for (var i = 0; i < elementstd.length; i++) {
      elementstd[i].style.width = `${tdCell - 20}px`;
    }
  }, [hover, hoverId]);

  const renderDraggableElemnts = React.useCallback(
    (provided, snapshot) => {
      // Rendering elements
      return (
        <div {...provided.droppableProps} ref={provided.innerRef} className={`elements ${snapshot.isDraggingOver ? 'dragging-over-treso' : ''}`}>
          <Draggable draggableId={dragPrefix} index={Number(indexMultiplier)}>
            {(provided, snapshot) => (
              <div
                id={monthElements?.id}
                onDoubleClick={() => {
                  toggleEditElement(monthElements.id);
                }}
                onMouseEnter={(e) => handleMouseEnter(e)}
                onMouseLeave={() => handleMouseLeave()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={`element ${
                  snapshot.isDragging
                    ? 'ghosting'
                    : mode === 'compta'
                    ? monthElements?.type === 'Relle'
                      ? 'elementgraphe'
                      : 'elementgrapheCompta'
                    : monthElements?.statut && monthElements?.statut === 3 && monthElements?.type === 'Relle'
                    ? 'elementPointed'
                    : monthElements?.statut && monthElements?.statut === 2 && monthElements?.type === 'Relle'
                    ? 'elementEngaged'
                    : monthElements?.statut && monthElements?.statut === 1 && (monthElements?.type === 'Relle' || monthElements?.type === 'Simulation')
                    ? 'elementProvisional'
                    : 'elementgrapheCompta'
                }`}>
                {!monthElements ? null : editElement.status && monthElements?.id == editElement.id ? (
                  <div className="addElement">
                    <Input
                      name="libelle"
                      onPressEnter={(e) => {
                        handleUpdateEelementLibelle(e, monthElements.id);
                      }}
                      defaultValue={monthElements.label.toString()}
                    />
                    <Input
                      name="montant"
                      onPressEnter={(e) => {
                        handleUpdateEelementMontant(e, monthElements.id);
                      }}
                      defaultValue={monthElements.value.toString()}
                    />
                  </div>
                ) : (
                  <>
                    <div className="labelElement">{monthElements.label.length > 8 ? monthElements.label?.slice(0, 8) + '...' : monthElements.label}</div>
                    <div>
                      {monthElements.value.toLocaleString('fi-FI', {
                        style: 'currency',
                        currency: 'EUR'
                      })}
                    </div>
                  </>
                )}
                {hover && monthElements?.id == hoverId && monthElements.label && monthElements.montant?.length != 0 && !editElement.status ? (
                  <div className="ajoutElementCell">
                    <div className={'elementCell'}>
                      {monthFilter === undefined && (
                        <>
                          <Popover overlayClassName="popover-add-element" placement="right" title={'Ajouter element'} content={content} trigger="click">
                            <img src={ajoutElement} />
                          </Popover>
                          <hr width="100%" color="black" size="20" />
                        </>
                      )}
                      <Popover placement="right" content={() => contentPopover(monthElements.id, monthElements.type, monthElements.statut)} trigger="click">
                        <img src={gear} />
                      </Popover>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div style={{ display: 'none' }}>{'test'}</div>
              </div>
            )}
          </Draggable>
        </div>
      );
    },
    [categoryName, dragPrefix, indexMultiplier, monthElements, editElement, hover, hoverId]
  );
  return (
    <>
    
      <Droppable droppableId={dragPrefix}>{(provided, snapshot) => <>{renderDraggableElemnts(provided, snapshot)}</>}</Droppable>

      {isModalVisible && (
        <PopupCustomer
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          setIsPopverVisible={setIsPopverVisible}
          typeEcritureDefault={typeElement}
          date={chekDate(month)}
          listingElement={listingElement}
          months={months}
          elementUpdate={elementUpdate}
          updateElementPop={updateElementPop}
        />
      )}
    </>
  );
};

export default CellElement;
