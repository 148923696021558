import { Modal, Button, Form, Input, Select, DatePicker, TreeSelect } from 'antd';
import './PopupCustomer.scss';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createToogle, filterDateAction, listCategorie, listCategoriePopup, listSolde } from '../../redux/actions/categories';
import { useLocation } from 'react-router-dom';

//icon
import reel from '../../assets/icon/reel.svg';
import simulation from '../../assets/icon/simulationPopup.svg';
import prev from '../../assets/icon/prev.svg';
import engage from '../../assets/icon/engage.svg';
import pointe from '../../assets/icon/pointe.svg';

import { createElement, deleteElement, updateElement } from '../../redux/actions/element';
import moment from 'moment';
import { nestCategories } from '../../helpers/helpers';

const PopupCustomer = ({
  isModalVisible,
  typeEcritureDefault,
  setIsModalVisible,
  date,
  category,
  categoryId,
  dateActive,
  elementUpdate,
  updateElementPop,
  title,
  months,
  listingElement
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [libelle, setLibelle] = useState('');
  const [montant, setMontant] = useState('');
  const [dateCompta, setDateCompta] = useState(date);
  const [dateComptaUpdate, setDateComptaUpdate] = useState(elementUpdate ? elementUpdate[0]?.dateCompta : '');
  const [dateTresoUpdate, setDateTresoUpdate] = useState(elementUpdate ? elementUpdate[0]?.dateTreso : '');
  const [dateTreso, setDateTreso] = useState(date);
  const [categorieId, setCategorie] = useState([category]);
  const [categorieIdUpdate, setCategorieUpdate] = useState();
  const [statutId, setStatut] = useState(1);
  const [statutUpdateId, setStatutUpdate] = useState(elementUpdate ? elementUpdate[0]?.statutId : '');
  const [listCategories, setListCategories] = useState([]);

  const [type, setTypeEcriture] = useState(typeEcritureDefault || elementUpdate[0]?.type);
  const listsAll = useSelector((state) => state.categories.categories);
  const elements = useSelector((state) => state.categories.elements);
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const monthFilter = useSelector((state) => state.categories.monthFilter);
  const userId = JSON.parse(localStorage.getItem('user')).id;
  const mode = useSelector((state) => state.categories.mode);
  const toogleCreate = useSelector((state) => state.categories.toogleCreate);

  const dispatch = useDispatch();
  let location = useLocation();
  useEffect(() => {
    dispatch(listCategoriePopup(userId));
  }, []);

  useEffect(() => {
    const result = listsAll?.map((c) => ({
      ...c,
      key: c.id,
      value: c.id,
      title: c.name
    }));
    setListCategories(nestCategories(result));
  }, [listsAll]);

  const onChangeDateCompta = (date, dateString) => {
    setDateCompta(dateString);
    setDateComptaUpdate(dateString);
  };
  const onChangeDateTreso = (date, dateString) => {
    setDateTreso(dateString);
    setDateTresoUpdate(dateString);
  };
  const onChangeLibelle = (e) => {
    setLibelle(e.target.value);
  };
  const onChangeMontant = (e) => {
    setMontant(e.target.value);
  };
  const onChangeStatut = (value) => {
    setStatut(value);
    setStatutUpdate(value);
  };
  const onChangeCategorie = (value, event) => {
    setCategorie(value.value);
    setCategorieUpdate(value.value);
  };
  const onChangeTypeEcriture = (value, event) => {
    setTypeEcriture(value);
  };

  const handleOk = async () => {
    await form.validateFields();
    const element = {
      libelle,
      montant,
      dateCompta: dateCompta,
      dateTreso: dateTreso,
      categorieId: categoryId || categorieId,
      type: type,
      statutId: statutId,
      userId: userId
    };

    if (location.pathname === '/') {
      await dispatch(
        createElement(element, 'treso', dateFilter.startDate, dateFilter.endDate, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
    } else if (location.pathname === '/table-compta') {
      await dispatch(
        createElement(element, 'compta', dateFilter.startDate, dateFilter.endDate, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
    } else if (location.pathname === '/categories' && mode === 'treso') {
      await dispatch(
        createElement(element, 'treso', dateFilter.startDate, dateFilter.endDate, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
    } else if (location.pathname === '/categories' && mode === 'compta') {
      await dispatch(
        createElement(element, 'compta', dateFilter.startDate, dateFilter.endDate, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
    }

    setIsModalVisible(false);
    dispatch(filterDateAction(dateFilter.startDate, dateFilter.endDate, months));
    dispatch(createToogle(!toogleCreate));
  };

  const handlevaliderAndAdd = async () => {
    await form.validateFields();
    const element = { libelle, montant, dateCompta, dateTreso, categorieId, type, statutId, userId: userId };
    if (location.pathname === '/') {
      await dispatch(createElement(element, 'treso', dateFilter.startDate, dateFilter.endDate, dateFilter.startDateSynthese, dateFilter.endDateSynthese));
    } else if (location.pathname === '/table-compta') {
      await dispatch(createElement(element, 'compta', dateFilter.startDate, dateFilter.endDate, dateFilter.startDateSynthese, dateFilter.endDateSynthese));
    } else if (location.pathname === '/categories' && mode === 'treso') {
      await dispatch(createElement(element, 'treso', dateFilter.startDate, dateFilter.endDate, dateFilter.startDateSynthese, dateFilter.endDateSynthese));
    } else if (location.pathname === '/categories' && mode === 'compta') {
      await dispatch(
        createElement(element, 'compta', dateFilter.startDate, dateFilter.endDate, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
    }
    dispatch(createToogle(!toogleCreate));
    dispatch(listCategoriePopup(userId));
    dispatch(filterDateAction(dateFilter.startDate, dateFilter.endDate, months));
    form.setFieldsValue({ Libellé: '', Montant: '', DateComptabilité: '', Datetrésorerie: '', statut: '', typeEcriture: '', Catégories: listsAll });
  };

  const updateElementbydate = async () => {
    await form.validateFields();
    const newElement = {
      libelle: libelle || elementUpdate[0]?.libelle,
      montant: montant || elementUpdate[0]?.montant,
      dateCompta: dateComptaUpdate || moment(elementUpdate[0]?.dateCompta, 'DD-MM-YYYY'),
      dateTreso: dateTresoUpdate || moment(elementUpdate[0]?.dateTreso, 'DD-MM-YYYY'),
      categorieId: categorieIdUpdate,
      type: type || elementUpdate[0]?.type,
      statutId: statutUpdateId,
      userId: userId
    };
    dispatch(
      updateElement(
        elementUpdate[0].id,
        newElement,
        title,
        dateFilter.startDate,
        dateFilter.endDate,
        monthFilter,
        dateFilter.startDateSynthese,
        dateFilter.endDateSynthese
      )
    );
    setIsModalVisible(false);
    dispatch(createToogle(!toogleCreate));
    if (location.pathname === '/') {
      dispatch(listCategorie('treso', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId));
      dispatch(listSolde('treso', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId));
    } else if (location.pathname === '/table-compta') {
      dispatch(
        listCategorie('compta', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
      dispatch(
        listSolde('compta', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
    }
  };

  const handleCancel = () => {
    // setIsPopverVisible(true);
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };

  return (
    <>
      {!updateElementPop ? (
        <Modal
          open={isModalVisible}
          width={290}
          closable={false}
          bodyStyle={{
            backgroundColor: '#F2F2F2'
          }}
          footer={[
            <div key="Submit" className="footer">
              <div key="keyConfirmation" className="btn_Confirmation">
                <Button key="annuler" onClick={() => handleCancel()} className="annuler">
                  Annuler
                </Button>
                <Button key="valider" className="valider" onClick={() => handleOk()}>
                  Valider
                </Button>
              </div>
              {dateActive ? (
                <div className="btn_add">
                  <Button key="add" onClick={() => handlevaliderAndAdd()} className="add">
                    valider et ajouter une nouvelle écriture
                  </Button>
                </div>
              ) : null}
            </div>
          ]}>
          <Form
            form={form}
            className="ecriture-form"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}>
            <Form.Item
              label="Libellé"
              name="Libellé"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre libelle'
                }
              ]}>
              <Input placeholder="libelle" value={libelle} onChange={onChangeLibelle} />
            </Form.Item>
            <Form.Item
              label="Montant"
              name="Montant"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre Montant'
                }
              ]}>
              <Input placeholder="Montant" value={montant} onChange={onChangeMontant} />
            </Form.Item>
            {dateActive ? (
              <Form.Item
                label="Date Comptabilité"
                name="DateComptabilité"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: 'Veuillez saisir Date Comptabilité'
                  }
                ]}>
                <DatePicker format="DD-MM-YYYY" onChange={onChangeDateCompta} style={{ width: '100%' }} placeholder="--" />
              </Form.Item>
            ) : (
              <Form.Item
                label="Date Comptabilité"
                name="DateComptabilité"
                labelCol={{ span: 24 }}
                initialValue={moment(dateCompta, 'DD-MM-YYYY')}
                rules={[
                  {
                    required: true,
                    message: 'Veuillez saisir Date Comptabilité'
                  }
                ]}>
                <DatePicker
                  format="DD-MM-YYYY"
                  onChange={onChangeDateCompta}
                  style={{ width: '100%' }}
                  placeholder="--"
                  defaultValue={moment(dateCompta, 'DD-MM-YYYY')}
                />
              </Form.Item>
            )}
            {dateActive ? (
              <Form.Item
                label="Date trésorerie"
                name="Datetrésorerie"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: 'Veuillez saisir Date Trésorerie'
                  }
                ]}>
                <DatePicker format="DD-MM-YYYY" onChange={onChangeDateTreso} style={{ width: '100%' }} placeholder="--" />
              </Form.Item>
            ) : (
              <Form.Item
                label="Date trésorerie"
                name="Datetrésorerie"
                labelCol={{ span: 24 }}
                initialValue={moment(dateTreso, 'DD-MM-YYYY')}
                rules={[
                  {
                    required: true,
                    message: 'Veuillez saisir Date Trésorerie'
                  }
                ]}>
                <DatePicker
                  format="DD-MM-YYYY"
                  onChange={onChangeDateTreso}
                  style={{ width: '100%' }}
                  placeholder="--"
                  defaultValue={moment(dateTreso, 'DD-MM-YYYY')}
                />
              </Form.Item>
            )}
            <Form.Item
              label="Catégories"
              name="Catégories"
              labelCol={{ span: 24 }}
              initialValue={category}
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre Catégories'
                }
              ]}>
              {dateActive || listingElement ? (
                <TreeSelect
                  style={{ width: '100%' }}
                  value={1}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={listCategories}
                  labelInValue
                  placeholder="CHOISIR la CATÉGORIE"
                  onChange={onChangeCategorie}
                />
              ) : (
                <Input placeholder="Catégories" defaultValue={category} />
              )}
            </Form.Item>
            <Form.Item
              label="Type d'écriture"
              name="typeEcriture"
              labelCol={{ span: 24 }}
              initialValue={type}
              rules={[
                {
                  required: true,
                  message: "Veuillez saisir votre Type d'écriture"
                }
              ]}>
              <Select defaultValue={type} onSelect={(value, event) => onChangeTypeEcriture(value, event)}>
                <>
                  <Option value="Relle">
                    <div className="option">
                      <img src={reel} />
                      <span>RÉEL</span>
                    </div>
                  </Option>
                  <Option value="Simulation">
                    <div className="option">
                      <img src={simulation} />
                      <span>DE SIMULATION</span>
                    </div>
                  </Option>
                </>
              </Select>
            </Form.Item>
            <Form.Item
              label="Statut"
              name="statut"
              labelCol={{ span: 24 }}
              initialValue={'Prévisionnel'}
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre Statut'
                }
              ]}>
              <Select defaultValue={1} onSelect={(value, event) => onChangeStatut(value, event)}>
                {type === 'Simulation' && (
                  <>
                    <Option value="1">
                      <div className="option">
                        <img src={prev} />
                        <span>Prévisionnel</span>
                      </div>
                    </Option>
                  </>
                )}
                {type === 'Relle' && (
                  <>
                    <Option value="1">
                      <div className="option">
                        <img src={prev} />
                        <span>Prévisionnel</span>
                      </div>
                    </Option>
                    <Option value="2">
                      <div className="option">
                        <img src={engage} />
                        <span>Engagé</span>
                      </div>
                    </Option>
                    <Option value="3">
                      <div className="option">
                        <img src={pointe} />
                        <span>Pointé</span>
                      </div>
                    </Option>
                  </>
                )}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      ) : (
        <Modal
          open={isModalVisible}
          width={290}
          closable={false}
          bodyStyle={{
            backgroundColor: '#F2F2F2'
          }}
          footer={[
            <div key="Submit" className="footer">
              <div key="keyConfirmation" className="btn_Confirmation">
                <Button key="annuler" onClick={() => handleCancel()} className="annuler">
                  Annuler
                </Button>
                <Button key="valider" className="valider" onClick={() => updateElementbydate()}>
                  Modifier
                </Button>
              </div>
            </div>
          ]}>
          <Form
            form={form}
            className="ecriture-form"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}>
            <Form.Item
              label="Libellé"
              name="Libellé"
              labelCol={{ span: 24 }}
              initialValue={elementUpdate[0]?.libelle}
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre libelle'
                }
              ]}>
              <Input placeholder="libelle" value={libelle} onChange={onChangeLibelle} defaultValue={elementUpdate[0]?.libelle} />
            </Form.Item>
            <Form.Item
              label="Montant"
              name="Montant"
              labelCol={{ span: 24 }}
              initialValue={elementUpdate[0]?.montant}
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre Montant'
                }
              ]}>
              <Input placeholder="Montant" value={montant} onChange={onChangeMontant} defaultValue={elementUpdate[0]?.montant} />
            </Form.Item>
            <Form.Item
              label="Date Comptabilité"
              name="DateComptabilité"
              labelCol={{ span: 24 }}
              initialValue={moment(dateComptaUpdate, 'DD-MM-YYYY')}
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir Date Comptabilité'
                }
              ]}>
              <DatePicker format="DD-MM-YYYY" onChange={onChangeDateCompta} style={{ width: '100%' }} placeholder="--" defaultValue={dateComptaUpdate} />
            </Form.Item>
            <Form.Item
              label="Date trésorerie"
              name="Datetrésorerie"
              labelCol={{ span: 24 }}
              initialValue={moment(dateTresoUpdate, 'DD-MM-YYYY')}
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir Date Trésorerie'
                }
              ]}>
              <DatePicker format="DD-MM-YYYY" onChange={onChangeDateTreso} style={{ width: '100%' }} placeholder="--" defaultValue={dateTresoUpdate} />
            </Form.Item>
            <Form.Item
              label="Catégories"
              name="Catégories"
              labelCol={{ span: 24 }}
              initialValue={category}
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre Catégories'
                }
              ]}>
              <TreeSelect
                style={{ width: '100%' }}
                value={1}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={listCategories}
                labelInValue
                placeholder="CHOISIR la CATÉGORIE"
                onChange={onChangeCategorie}
              />
            </Form.Item>
            <Form.Item
              label="Type d'écriture"
              name="typeEcriture"
              labelCol={{ span: 24 }}
              initialValue={elementUpdate[0]?.type}
              rules={[
                {
                  required: true,
                  message: "Veuillez saisir votre Type d'écriture"
                }
              ]}>
              <Select defaultValue={elementUpdate[0]?.type} onSelect={(value, event) => onChangeTypeEcriture(value, event)}>
                <>
                  <Option value="Relle">
                    <div className="option">
                      <img src={reel} />
                      <span>RÉEL</span>
                    </div>
                  </Option>
                  <Option value="Simulation">
                    <div className="option">
                      <img src={simulation} />
                      <span>DE SIMULATION</span>
                    </div>
                  </Option>
                </>
              </Select>
            </Form.Item>
            <Form.Item
              label="Statut"
              name="statut"
              labelCol={{ span: 24 }}
              initialValue={elementUpdate[0]?.statutId == 1 ? 'Prévisionnel' : elementUpdate[0]?.statutId == 2 ? 'Engagé' : 'Pointé'}
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre Statut'
                }
              ]}>
              <Select defaultValue={elementUpdate[0]?.statutId} onSelect={(value, event) => onChangeStatut(value, event)}>
                {type === 'Simulation' && (
                  <>
                    <Option value="1">
                      <div className="option">
                        <img src={prev} />
                        <span>Prévisionnel</span>
                      </div>
                    </Option>
                  </>
                )}
                {type === 'Relle' && (
                  <>
                    <Option value="1">
                      <div className="option">
                        <img src={prev} />
                        <span>Prévisionnel</span>
                      </div>
                    </Option>
                    <Option value="2">
                      <div className="option">
                        <img src={engage} />
                        <span>Engagé</span>
                      </div>
                    </Option>
                    <Option value="3">
                      <div className="option">
                        <img src={pointe} />
                        <span>Pointé</span>
                      </div>
                    </Option>
                  </>
                )}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default PopupCustomer;
