import React, { useEffect } from 'react';
import './Preloader.scss';
// Import Module progress Bar
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

// Import Image
import Logo from '../../assets/image/kwanto_logo.png';

const Preloader = ({ progress, setProgress }) => {
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 20;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  });

  const StyledLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: '#F1F1F1'
    },
    barColorPrimary: {
      backgroundColor: '#3399CC'
    },
    root: {
      height: 18
    }
  })(LinearProgress);

  return (
    <div className="preloader">
      <div className="preloader__loaderLogo">
        <img src={Logo} />
        <div className="preloader__loaderLogo__progressBar">
          <StyledLinearProgress variant="determinate" value={progress} />
        </div>
      </div>
    </div>
  );
};

export default Preloader;
