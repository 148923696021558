import React, { useEffect } from 'react';
import { Router, BrowserRouter, useNavigate } from 'react-router-dom';
import RouterComponent from './routes';
import isLoggedIn from './helpers/isLoggedIn';

import './App.scss';
import Home from './pages/Home/Home';
function App() {
  return (
    <React.Fragment>
      {/* <BrowserRouter>
        <Home />
      </BrowserRouter> */}

      <RouterComponent />
    </React.Fragment>
  );
}

export default App;
