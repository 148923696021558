import React from 'react';
import { Form, Input } from 'antd';

import './TextInput.css';

const TextInput = (props) => {
  const { required, message, label, name, icon, icon_ajustement, type } = props;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

  return (
    <>
      {name === 'password' || name === 'confirmPassword' ? (
        <Form.Item
          className="text-input_container"
          label={label}
          name={props.name}
          rules={[
            { required: required, message: message },
            {
              pattern: passwordRegex,
              message: 'Le mot de passe doit avoir au moins 8 caractères et contenir au moins une lettre majuscule, une lettre minuscule et un numéro!'
            }
          ]}>
          <Input.Password
            className="text-input"
            style={{ background: `url(${icon}) no-repeat scroll 7px 2px` }}
            placeholder={props.placeholder}
            type={type}
            rules
          />
        </Form.Item>
      ) : (
        <Form.Item
          className="text-input_container"
          label={label}
          name={props.name}
          rules={[
            { required: required, message: message },
            {
              type: type,
              message: 'Veuillez entrer une adresse courriel valide!'
            }
          ]}>
          <Input className="text-input" style={{ background: `url(${icon}) no-repeat scroll 7px ${icon_ajustement}` }} placeholder={props.placeholder} />
        </Form.Item>
      )}
    </>
  );
};

export default TextInput;
