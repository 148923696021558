// This file was created to avoid a semantic HTML error
import * as React from 'react';
import { Input } from 'antd';
import { getQueryStringParams } from '../../helpers/helpers';
import ajoutSousBudget from '../../assets/icon/ajoutSousBudget.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
  createCategorie,
  createObjectiveWithCategory,
  deleteCategorie,
  editingAction,
  editObjectiveMontant,
  listCategorie,
  updateCategorie,
  updateObjectiveWithCategory
} from '../../redux/actions/categories';
import { Draggable, Droppable } from 'react-beautiful-dnd';

export default function Td({
  children,
  expanded,
  dataIndex,
  refresh,
  setRefresh,
  dataSource,
  setDataSource,
  expandedCategories,
  setExpandedCategories,
  indexMultiplier,
  dragPrefix,
  ...props
}) {
  const editing = useSelector((state) => state.categories.editing);
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const checkedSynthese = useSelector((state) => state.categories.checkedSynthese);
  const checkedObjectif = useSelector((state) => state.categories.checkedObjectif);
  const monthFilter = useSelector((state) => state.categories.monthFilter);
  const editingObjective = useSelector((state) => state.categories.editingObjective);
  const list = useSelector((state) => state.categories.categories);
  const userId = JSON.parse(localStorage.getItem('user')).id;
  const SelectedObjective = useSelector((state) => state.categories.SelectedObjective);

  const dispatch = useDispatch();
  const inputRef = React.useRef(null);
  React.useEffect(() => {
    inputRef?.current?.focus();
  });

  const toggleEdit = (e) => {
    dispatch(editingAction(true, e.target.id));
  };
  const save = async (e) => {
    try {
      if (dataIndex === 'objectif') {
        const dataObjectiveCategoy = {
          categorie_id: editingObjective?.id,
          objective_id: SelectedObjective?.id,
          montant: e.target.value,
          userId: userId
        };
        await dispatch(
          updateObjectiveWithCategory(
            editingObjective?.id,
            dataObjectiveCategoy,
            'treso',
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilter,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese
          )
        );
        await dispatch(editObjectiveMontant(false));
      } else {
        if (props.record.id === undefined) {
          if (dataSource?.length == 0) {
            await dispatch(createCategorie({ name: e.target.value, levelId: 1, userId: userId, index: 0 }));
            await dispatch(
              listCategorie('treso', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
            );
          } else if (dataSource?.length > 0) {
            await dispatch(createCategorie({ name: e.target.value, levelId: 1, userId: userId, index: dataSource?.length - 1 }));
            await dispatch(
              listCategorie('treso', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
            );
          }
        } else {
          if (e.target.value.length > 0) {
            dispatch(
              updateCategorie(
                props.record.id,
                { name: e.target.value, userId: userId },
                dateFilter.startDate,
                dateFilter.endDate,
                monthFilter,
                dateFilter.startDateSynthese,
                dateFilter.endDateSynthese
              )
            );
          } else {
            dispatch(deleteCategorie(props.record.id));
          }
        }
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  const handleAddSUb = async () => {
    setExpandedCategories(() => {
      const index = expandedCategories.findIndex((ec) => ec === props.record.key);
      if (index > -1 && !expanded) return expandedCategories.filter((ec) => ec !== props.record.key);
      else return [...expandedCategories, props.record.key];
    });
    if (props.record?.children === undefined) {
      const newChild = {
        name: '',
        parentId: props.record.key,
        levelId: props.record.levelId + 1,
        userId: userId,
        index: 0
      };
      setDataSource([...dataSource]);
      dispatch(createCategorie(newChild));
      dispatch(editingAction(true));
    } else if (props.record?.children?.length > 0) {
      const newChild = {
        name: '',
        parentId: props.record.key,
        levelId: props.record.levelId + 1,
        userId: userId,
        index: props.record?.children?.length
      };
      setDataSource([...dataSource]);
      dispatch(createCategorie(newChild));
      dispatch(editingAction(true));
    }
  };
  const renderDraggableFirst = (provided, snapshot) => {
    // Rendering switch category
    return <div {...provided.droppableProps} ref={provided.innerRef} className={`catswitchFirst ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}></div>;
  };
  const renderDraggable = (provided, snapshot) => {
    // Rendering switch category
    return <div {...provided.droppableProps} ref={provided.innerRef} className={`catswitch ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}></div>;
  };

  return (
    <td
      className={
        dataIndex && dataIndex === 'name'
          ? 'table-td ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last cattegory'
          : dataIndex && dataIndex === 'delete'
          ? 'table-td ant-table-cell ant-table-cell-fix-right ant-table-cell-fix-right-last action'
          : dataIndex && dataIndex === 'synthese' && checkedSynthese && checkedObjectif
          ? 'table-td ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last synthese'
          : dataIndex && dataIndex === 'objectif' && checkedObjectif
          ? 'table-td ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last objectif'
          : dataIndex && dataIndex === 'synthese' && !checkedObjectif && checkedSynthese
          ? 'table-td ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last syntheseNotObjective'
          : 'table-td '
      }>
      {dataIndex && dataIndex === 'name' && props.record.index == 0 && (
        <Droppable
          droppableId={
            'category=' +
            props.record.id +
            '&month=' +
            'CATEGORY' +
            '&levelId=' +
            props.record.levelId +
            '&index=' +
            props.record.index +
            '&parentId=' +
            props.record.parentId +
            '&switch=true' +
            '&first=true'
          }>
          {(provided, snapshot) => <>{renderDraggableFirst(provided, snapshot)}</>}
        </Droppable>
      )}
      {(dataIndex === 'name' && !props.record?.id && editing) ||
      (props.record?.name?.length == 0 && dataIndex === 'name' && editing) ||
      (dataIndex === 'objectif' && editingObjective?.status && editingObjective?.id === props.record?.id) ? (
        <>
          <Input ref={inputRef} onPressEnter={save} onBlur={save} defaultValue={editingObjective?.value} />
        </>
      ) : (
        <>
          <div
            style={
              dataIndex && dataIndex === 'name'
                ? {
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: '10px'
                  }
                : {}
            }
            onDoubleClick={(e) => toggleEdit(e)}>
            {children?.map((c) => {
              return c ? (
                <div key={getQueryStringParams(c?.props.dragPrefix).category || new Date().toString()}>{React.cloneElement(c, { expanded })}</div>
              ) : null;
            })}
            {dataIndex === 'name' && props.record.levelId !== 5 && (
              <div className="addSubCat">
                <img src={ajoutSousBudget} className="ajoutBSousudget" onClick={() => handleAddSUb()} />
              </div>
            )}
          </div>
        </>
      )}
      {dataIndex && dataIndex === 'name' && (
        <Droppable
          droppableId={
            'category=' +
            props.record.id +
            '&month=' +
            'CATEGORY' +
            '&levelId=' +
            props.record.levelId +
            '&index=' +
            props.record.index +
            '&parentId=' +
            props.record.parentId +
            '&switch=true'
          }>
          {(provided, snapshot) => <>{renderDraggable(provided, snapshot)}</>}
        </Droppable>
      )}
    </td>
  );
}
