import { Table } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDefaultColumnsElements, nestCategories } from '../../helpers/helpers';
import Cell from '../CustomCell/CustomCell';
import DragAndDropElements from './DragandDropElement';
import Td from '../Td/Td';
import Thead from '../Thead/Thead';
import './CustomTableElement.css';
import TdElement from './TdElement';
import CellElement from './cell/CellElement';
import { getonlyElements, modeElement } from '../../redux/actions/element';
import { listCategorie } from '../../redux/actions/categories';
function CustomTableElement({ title, classStyle }) {
  const monthFilter = useSelector((state) => state.categories.monthFilter);
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const listsSolde = useSelector((state) => state.categories.soldeAll);
  const mode = useSelector((state) => state.categories.mode);
  const [dataSource, setDataSource] = React.useState([]);
  const onlyelements = useSelector((state) => state.categories.onlyElements);
  const toogleCreate = useSelector((state) => state.categories.toogleCreate);
  const monthsTotalData = React.useMemo(() => listsSolde || [], [listsSolde]);
  const userId = JSON.parse(localStorage.getItem('user')).id;

  let dateNow = new Date();

  const [startDate, setstartDate] = React.useState(dateNow.getFullYear() + '-' + 1);
  const [endDate, setendDate] = React.useState(dateNow.getFullYear() + '-' + 12);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (mode === 'compta') {
      dispatch(modeElement('compta'));
      dispatch(getonlyElements(userId, 'dateCompta', '', '', startDate, endDate));
    } else {
      dispatch(modeElement('treso'));
      dispatch(getonlyElements(userId, 'dateTreso', '', '', startDate, endDate));
    }
  }, [toogleCreate, mode, dateFilter]);

  React.useEffect(() => {
    dispatch(
      listCategorie('treso', dateFilter?.startDate, dateFilter?.endDate, monthFilter, dateFilter?.startDateSynthese, dateFilter?.endDateSynthese, userId)
    );
  }, []);

  React.useEffect(() => {
    setDataSource(onlyelements);
  }, [onlyelements]);

  const [windowSize, setWindowSize] = React.useState(getWindowSize());

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const renderCell = React.useCallback(
    (props) => {
      return <CellElement {...props} mode={mode} />;
    },
    [mode]
  );

  const defaultColumns = React.useMemo(() => getDefaultColumnsElements(renderCell, title, dateFilter, mode), [renderCell, dateFilter, title, mode]);

  const columns = React.useMemo(
    () =>
      defaultColumns.map((col) => {
        return {
          ...col,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            className: col.className
          })
        };
      }),
    [defaultColumns]
  );

  return (
    <>
      <Table
        components={{
          header: {
            wrapper: (props) => <Thead {...props} monthsTotalData={monthsTotalData} tableWithElements={true} />
          },
          body: {
            wrapper: (props) => <DragAndDropElements {...props} />,
            cell: (props) => <TdElement {...props} dataSource={dataSource} setDataSource={setDataSource} />
          }
        }}
        bordered
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        size="small"
        scroll={{
          x: monthFilter === undefined ? 2500 : 1200,
          y: windowSize.innerWidth < 1900 ? 'calc(100vh - 30vh)' : 'calc(100vh - 22vh)',
          thumb: { width: '50px', background: '#33cc00' }
        }}
      />
    </>
  );
}

export default CustomTableElement;
