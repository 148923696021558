import * as React from 'react';
import { Popconfirm, Spin, Switch, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Thead from '../Thead/Thead';
import Cell from '../CustomCell/CustomCell';
import Td from '../Td/Td';
import DragAndDropContext from '../DragAndDropContext/DragAndDropContext';
import { getDefaultColumns, MONTHS, nestCategories } from '../../helpers/helpers';
import 'antd/dist/antd.css';
import './CustomeTable.css';
import { checkedSynthse, deleteCategorie, deleteCategorieOne, filterDateAction, listCategorie, listSolde } from '../../redux/actions/categories';
import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

import Tr from '../Tr/Tr';
import { addNewObjectif } from '../../redux/actions/objective';

const CustomTable = ({ title }) => {
  const lists = useSelector((state) => state.categories.categories);
  const listsSolde = useSelector((state) => state.categories.soldeAll);
  const loading = useSelector((state) => state.categories.showLoading);
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const checkedSynthese = useSelector((state) => state.categories.checkedSynthese);
  const checkedObjectif = useSelector((state) => state.categories.checkedObjectif);
  const monthFilter = useSelector((state) => state.categories.monthFilter);
  const SelectedObjective = useSelector((state) => state.categories.SelectedObjective);
  const userId = JSON.parse(localStorage.getItem('user')).id;

  const dispatch = useDispatch();

  const [expandedCategories, setExpandedCategories] = React.useState([]);

  const [windowSize, setWindowSize] = React.useState(getWindowSize());

  const [refresh, setRefresh] = React.useState(false);

  // Data supposed from an API request ( its not nested )
  const [apiData, setApiData] = React.useState([]);

  // dataSource after nesting and adding some other attributes
  // ( check the useEffect ) inside App.tsx
  const [dataSource, setDataSource] = React.useState([]);

  // Each month's total ( supposed to be from an API request )
  const monthsTotalData = React.useMemo(() => listsSolde || [], [listsSolde]);

  // Every cell render method
  // it includes category rendering and elements rendering -> check ./components/Cell.tsx
  const categoryExpanded = React.useCallback(
    (recordId) => {
      return expandedCategories.includes(recordId);
    },
    [expandedCategories]
  );

  // Function Delete Catgeory
  const handleDeleteCategory = async (key) => {
    const deltetdCat = lists?.find((c) => c.id == key);

    let arrayResulUpdateCat = [];
    lists
      .filter((cat) => cat.parentId == deltetdCat.parentId)
      .forEach((el) => {
        if (el.index > deltetdCat.index) {
          let obj = { ...el };
          obj.index -= 1;
          arrayResulUpdateCat.push(obj);
        }
      });
    let data = {
      categoreisDeletedId: key,
      categories: arrayResulUpdateCat
    };
    await dispatch(deleteCategorieOne(userId, data, '', dateFilter?.startDate, dateFilter?.endDate, dateFilter.startDateSynthese, dateFilter.endDateSynthese));
    if (title === 'Trésorerie') {
      await dispatch(
        listCategorie('treso', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
      await dispatch(
        listSolde('treso', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
    } else if (title === 'Comptabilité') {
      await listCategorie('compta', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId);
      await dispatch(
        listSolde('compta', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
    }
  };

  const renderCell = React.useCallback(
    (props) => {
      return <Cell {...props} expandedCategories={expandedCategories} setExpandedCategories={setExpandedCategories} />;
    },
    [expandedCategories, setExpandedCategories]
  );

  const addObjective = (e) => {
    const dataObjectif = {
      nameObjective: e.target.value,
      userId: userId
    };
    dispatch(addNewObjectif(dataObjectif, 'treso', dateFilter.startDate, dateFilter.endDate, dateFilter.startDateSynthese, dateFilter.endDateSynthese));
  };

  const renderAction = React.useCallback(
    (props) => {
      return (
        // Rendering actions
        <Popconfirm
          placement="bottomRight"
          title="Êtes-vous sûr？"
          icon={
            <QuestionCircleOutlined
              style={{
                color: 'red'
              }}
            />
          }
          onConfirm={() => handleDeleteCategory(props.id)}>
          <div className="btdelteCategory">
            <DeleteOutlined />
          </div>
        </Popconfirm>
      );
    },
    [dateFilter, lists]
  );

  const handleChangeDate = (value, dateString) => {
    const startDate = moment(dateString[0], 'YYYY-MM');
    const endDate = moment(dateString[1], 'YYYY-MM');
    dispatch(filterDateAction(dateFilter.startDate, dateFilter.endDate, dateFilter.monthArray, startDate.format('YYYY-MM'), endDate.format('YYYY-MM')));
  };

  // Getting table default columns
  // check ./helpers.ts ->  getDefaultColumns method for more info
  const defaultColumns = React.useMemo(
    () => getDefaultColumns(renderCell, title, renderAction, dateFilter, checkedSynthese, handleChangeDate, checkedObjectif, SelectedObjective, addObjective),
    [renderCell, title, renderAction, dateFilter, checkedSynthese, checkedObjectif, SelectedObjective, addObjective]
  );

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  React.useEffect(async () => {
    if (dateFilter) {
      if (title === 'Trésorerie') {
        await dispatch(
          listCategorie('treso', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
        await dispatch(
          listSolde('treso', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
      } else if (title === 'Comptabilité') {
        await dispatch(
          listCategorie('compta', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
        await dispatch(
          listSolde('compta', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
      }
    }
  }, [dispatch, dateFilter]);

  React.useEffect(() => {
    setApiData(lists);
  }, [lists]);

  // 1) First we need to add a key and name attribute for each category
  // 2) nesting categories so we can give it to ant table to give us nested children
  React.useEffect(() => {
    const result = apiData?.map((c) => ({
      ...c,
      key: c.id,
      name: c.name
    }));
    setDataSource(nestCategories(result));
  }, [apiData]);

  // Preparing final columns and sending required props for each cell.
  const columns = React.useMemo(
    () =>
      defaultColumns.map((col) => {
        return {
          ...col,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            className: col.className,
            // coords: record.name?.toLowerCase().replace(/ /g, '-') + "-" + col.dataIndex + "-dataindex=" + col.dataIndex, // this will be the draggable ID
            expanded: categoryExpanded(record.id)
          })
        };
      }),
    [categoryExpanded, defaultColumns]
  );

  const rowExpanded = React.useMemo(() => (expanded, record) => expanded || expandedCategories.includes(record.id), [expandedCategories]);

  const onExpand = (_, { key }) => {
    if (_) {
      setExpandedCategories([...expandedCategories, key]);
    } else {
      setExpandedCategories(Array.from(expandedCategories).filter((el) => el != key));
    }
  };

  // render
  return (
    <>
      <Table
        components={{
          header: {
            wrapper: (props) => <Thead {...props} monthsTotalData={monthsTotalData} dataSource={dataSource} setDataSource={setDataSource} title={title} />
          },
          body: {
            wrapper: (props) => (
              <DragAndDropContext
                {...props}
                apiData={apiData}
                setApiData={setApiData}
                expandedCategories={expandedCategories}
                setRefresh={setRefresh}
                refresh={refresh}
                setExpandedCategories={setExpandedCategories}
                title={title}
              />
            ),
            cell: (props) => (
              <Td
                {...props}
                setRefresh={setRefresh}
                refresh={refresh}
                dataSource={dataSource}
                setDataSource={setDataSource}
                expandedCategories={expandedCategories}
                setExpandedCategories={setExpandedCategories}
              />
            )
          }
        }}
        expandable={{
          onExpand: onExpand,
          expandedRowKeys: expandedCategories,
          expandIcon: ({ expanded, onExpand, record }) => {
            return (
              <>
                {
                  <button
                    onClick={(e) => {
                      setExpandedCategories(() => {
                        const index = expandedCategories.findIndex((ec) => ec === record.id);
                        if (index > -1) return expandedCategories.filter((ec) => ec !== record.id);
                        else return [...expandedCategories, record.id];
                      });
                      onExpand(record, e);
                    }}
                    className={'btExpand'}>
                    {rowExpanded(expanded, record) ? (
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path d="M8.46997 13.4599L12 9.93994L15.53 13.4599" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    ) : (
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path d="M8.46997 10.6399L12 14.1599L15.53 10.6399" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    )}{' '}
                  </button>
                }
              </>
            );
          }
        }}
        bordered
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        size="small"
        scroll={{
          x: dateFilter?.monthArray.length * 200,
          y: windowSize.innerWidth < 1900 ? 'calc(100vh - 30vh)' : 'calc(100vh - 22vh)',
          thumb: { width: '50px', background: '#33cc00' }
        }}
      />
      {loading && <Spin />}
    </>
  );
};
export default CustomTable;
