import React, { useState, useEffect, useRef } from 'react';
import './Header.scss';
import { DatePicker, Popover, Button, Tooltip, Select, Modal, Switch, Divider, Space, Input } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import kwanto from '../../assets/image/KWANTO.svg';
import filter from '../../assets/icon/filtre.svg';
import person from '../../assets/icon/user.svg';
import modeTreso from '../../assets/icon/modeTreso.svg';
import modeCompta from '../../assets/icon/modeCompta.svg';
import prev from '../../assets/icon/prev.svg';
import engage from '../../assets/icon/engage.svg';
import pointe from '../../assets/icon/pointe.svg';
import simulation from '../../assets/icon/simulation.svg';
import reel from '../../assets/icon/reel.svg';
import add from '../../assets/icon/add.svg';
import exportTable from '../../assets/icon/export.svg';
import InputSearch from '../InputSearch/InputSearch';
import PopupCustomer from '../PopupCustomer/PopupCustomer';
import {
  checkedObjectif,
  checkedSynthse,
  colorSoldeTotal,
  filterDateAction,
  listCategorie,
  listSolde,
  monthFilter,
  ObjectiveSelected
} from '../../redux/actions/categories';
import { filterSoldeByColor } from '../../helpers/filterSoldeByStatus';
import { deleteElement, getonlyElements, modeChart, modeElement } from '../../redux/actions/element';
import { addNewObjectif, deleteObjectif, getAllObjective } from '../../redux/actions/objective';
import * as XLSX from 'xlsx';

const Header = ({ sendDataElement }) => {
  const elements = useSelector((state) => state.categories.elements);
  const categories = useSelector((state) => state.categories.categories);
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const objectives = useSelector((state) => state.categories.objectives);
  const userId = JSON.parse(localStorage.getItem('user')).id;
  const SelectedObjective = useSelector((state) => state.categories.SelectedObjective);
  const checkedSynthese = useSelector((state) => state.categories.checkedSynthese);
  const { Option } = Select;
  const path = useLocation();
  let dateNow = new Date();
  const [active, setActive] = useState(false);
  const [modeActive, setModeActive] = useState(false);
  const [mode, setMode] = useState('treso');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [typeEcritureDefault, setTypeEcritureDefault] = useState('');
  const [valueStatut, setValueStatut] = useState();
  const [valueMonth, setValueMonth] = useState();
  const [nameCategory, setNameCategory] = useState('');
  const [idElement, setIdElement] = useState('');
  const [dateElement, setDateElement] = useState('');
  const [dateActive, setDateActive] = useState(false);
  const [startDate, setstartDate] = useState(dateNow.getFullYear() + '-' + 1);
  const [endDate, setendDate] = useState(dateNow.getFullYear() + '-' + 12);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [months, setMonths] = useState([
    'January 2023',
    'February 2023',
    'March 2023',
    'April 2023',
    'May 2023',
    'June 2023',
    'July 2023',
    'August 2023',
    'September 2023',
    'October 2023',
    'November 2023',
    'December 2023'
  ]);
  const [size, setSize] = useState('large');
  const [items, setItems] = useState([]);
  const [name, setName] = useState('');
  const inputRef = useRef(null);
  let location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname == '/') {
      setMode('treso');
    } else if (location.pathname == '/table-compta') {
      setMode('compta');
    } else if (location.pathname == '/categories') {
      setMode('categoriesTreso');
    } else if (location.pathname == '/categories/Compta') {
      setMode('categoriesCompta');
    }
  }, []);

  const checkElement = async () => {
    await elements?.map(async (el) => {
      if (el.libelle.length === 0 || el?.montant.length == 0) {
        setDateElement(el.dateTreso);
        setIdElement(el.id);
        categories.map((cat) => {
          if (el.categorieId === cat.id) {
            setNameCategory(cat.name);
          }
        });
        await setIsModalOpen(true);
      } else {
        await setIsModalOpen(false);
      }
    });
  };

  //Export csv
  const downloadExcel = () => {
    let dataCsv = [];
    elements.forEach((el) => {
      categories.map((cat) => {
        if (cat.id === el.categorieId) {
          el.name = cat.name;
          el.statut = el.statutId === 1 ? 'Prévisionnel' : el.statutId === 2 ? 'Engagé' : 'Pointé';
        }
      });
      let dataElement = {
        name: el.name,
        libelle: el.libelle,
        montant: el.montant.toLocaleString('fi-FI', {
          style: 'currency',
          currency: 'EUR'
        }),
        dateTreso: el.dateTreso,
        dateCompta: el.dateCompta,
        statut: el.statut,
        type: el.type
      };
      dataCsv.push(dataElement);
    });
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dataCsv);
    const header = ['Name', 'Libelle', 'Montant', 'DateTreso', 'DateCompta', 'Statut', 'Type'];
    XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'Table' + '.xlsx');
  };

  const handleClickMode = async (params) => {
    await checkElement();
    const color = filterSoldeByColor('');
    dispatch(colorSoldeTotal(color));
    if (params === 'treso') {
      setMode(params);
      dispatch(listCategorie(params, startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, SelectedObjective?.id));
      dispatch(listSolde('treso', '', startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, SelectedObjective?.id));
      setModeActive(!modeActive);
    } else if (params === 'compta') {
      setMode(params);
      dispatch(listCategorie(params, startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, SelectedObjective?.id));
      dispatch(
        listSolde('compta', '', startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, SelectedObjective?.id)
      );
      setModeActive(!modeActive);
    }
  };

  const handleClickActive = () => {
    setActive(!active);
  };

  const showModal = () => {
    checkElement();
    setIsModalVisible(true);
    setDateActive(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalVisible(false);
  };

  const handleCancel = async () => {
    if (mode === 'treso') {
      dispatch(deleteElement(idElement, 'Trésorerie'));
    } else if (mode === 'compta') {
      dispatch(deleteElement(idElement, 'Comptabilité'));
    }
    setIsModalOpen(false);
  };

  const content = () => {
    return (
      <div className="popup-ajouterEcriture">
        <Button
          className="simulation"
          value={'simulation'}
          onClick={(e) => {
            setTypeEcritureDefault('Simulation');
            setValueStatut(1);
            showModal();
          }}>
          DE SIMULATION
        </Button>

        <Button
          className="relle"
          value={'relle'}
          onClick={async (e) => {
            checkElement();
            setTypeEcritureDefault('Relle');
            setValueStatut(2);
            showModal();
          }}>
          RELLE
        </Button>
      </div>
    );
  };

  const handleChangeDate = (value, dateString) => {
    const startDate = moment(dateString[0], 'YYYY-MM');
    const endDate = moment(dateString[1], 'YYYY-MM');
    setstartDate(startDate.format('YYYY-MM'));
    setendDate(endDate.format('YYYY-MM'));
    const monthArray = [];
    let currentMonth = startDate;
    while (currentMonth <= endDate) {
      monthArray.push(currentMonth.format('MMMM YYYY'));
      currentMonth = currentMonth.add(1, 'month');
    }
    setMonths(monthArray);
    if (mode === 'treso') {
      dispatch(
        listSolde('treso', '', moment(dateString[0], 'YYYY-MM').format('YYYY-MM'), moment(dateString[1], 'YYYY-MM').format('YYYY-MM'), '', '', '', userId)
      );
    } else if (mode === 'compta') {
      dispatch(
        listSolde('compta', '', moment(dateString[0], 'YYYY-MM').format('YYYY-MM'), moment(dateString[1], 'YYYY-MM').format('YYYY-MM'), '', '', '', userId)
      );
    }
  };

  React.useEffect(() => {
    dispatch(filterDateAction(startDate, endDate, months, startDate, endDate));
    dispatch(getAllObjective(userId));
  }, [startDate, endDate, months, userId]);

  React.useEffect(() => {
    if (objectives) {
      objectives.forEach((el) => {
        setItems((prevItems) => [...prevItems, el.nameObjective]);
      });
    }
  }, [objectives]);

  const title = <span className="titlePopover">Ajouter une écriture</span>;

  const sortArrayofArray = (array, number) => {
    let sorted = number;
    let result = [];
    let actuArray = [];
    array.forEach((el, index) => {
      if (sorted > 1) {
        actuArray.push(el);
        sorted--;
      } else {
        actuArray.push(el);
        sorted = number;
        result.push([...actuArray]);
        actuArray = [];
      }
      /*  if (index === array.length - 1) {
        result.push([...actuArray]);
      } */
    });
    return result;
  };

  const handleChange = async (value) => {
    const monthArray = [];
    let currentMonth = moment(startDate);
    while (currentMonth <= moment(endDate)) {
      monthArray.push(currentMonth.format('MMMM YYYY'));
      currentMonth = currentMonth.add(1, 'month');
    }
    let resultSorted = sortArrayofArray(monthArray, value);
    let monthNew = [];
    resultSorted.forEach((el) => {
      let rst = '';
      el.forEach((sort, index) => {
        if (index === 0) {
          rst = sort;
        } else if (index === el.length - 1) {
          rst += ' - ' + sort;
        }
      });
      monthNew.push(rst);
    });
    setMonths(monthNew);
    setValueMonth(value);
    await dispatch(monthFilter(value));

    if (mode === 'treso') {
      await dispatch(listSolde('treso', '', startDate, endDate, '', '', '', userId));
    } else if (mode === 'compta') {
      await dispatch(listSolde('compta', '', startDate, endDate, '', '', '', userId));
    }
  };
  const options = [
    { label: 'Deux mois', value: 2 },
    { label: 'Trois mois', value: 3 },
    { label: 'Quatre mois', value: 4 },
    { label: 'Six mois', value: 6 },
    { label: 'Anneé', value: 12 }
  ];
  const onChangeSynthese = (checked) => {
    dispatch(checkedSynthse(checked));
  };

  const onChangeObjectif = (checked) => {
    if (objectives && objectives.length) {
      dispatch(ObjectiveSelected(objectives[0]));
    }
    dispatch(checkedObjectif(checked));
    dispatch(checkedSynthse(true));

    dispatch(listCategorie('treso', startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, objectives[0]?.id));
    dispatch(listSolde('treso', '', startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, objectives[0]?.id));
  };

  const addObjectif = () => {
    dispatch(checkedObjectif(true));
  };
  const changeObjective = async (value) => {
    let objectiveSeelected = {};
    await objectives.forEach((el) => {
      if (el.id == value) {
        objectiveSeelected = el;
        return el;
      }
    });
    await dispatch(ObjectiveSelected(objectiveSeelected));
    await dispatch(listCategorie('treso', startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, value));
    await dispatch(listSolde('treso', '', startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, value));
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    const dataObjectif = {
      nameObjective: name,
      userId: userId
    };
    dispatch(addNewObjectif(dataObjectif, 'treso', dateFilter.startDate, dateFilter.endDate, dateFilter.startDateSynthese, dateFilter.endDateSynthese));

    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  const deleteObjective = async (id) => {
    dispatch(deleteObjectif(id, userId));
  };

  const handleClickModeElement = (params) => {
    dispatch(modeElement(params));
    if (params === 'treso') {
      dispatch(getonlyElements(userId, 'dateTreso', '', '', dateFilter.startDate, dateFilter.endDate));
    } else if (params === 'compta') {
      dispatch(getonlyElements(userId, 'dateCompta', '', '', dateFilter.startDate, dateFilter.endDate));
    }
  };

  const handleClickModeChart = (params) => {
    dispatch(modeChart(params));
  };
  return (
    <>
      <div className="header">
        <div className="header__left">
          <div className="header__left__logo">
            <Link to="/">
              <img src={kwanto} />
            </Link>
          </div>
          <div className="header__left__datePicker">
            <RangePicker
              className="rangePicker"
              picker="month"
              onChange={handleChangeDate}
              defaultValue={[dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')]}
            />
          </div>
          <div className="selectGroupCompact">
            {!path.pathname.includes('categories') && !path.pathname.includes('chart') && (
              <Select
                size={size}
                optionLabelProp="label"
                allowClear={true}
                onChange={handleChange}
                style={{
                  width: 35
                }}
                options={options.map((item) => ({
                  value: item.value,
                  label: item.label
                }))}
                bordered
                placement="bottomRight"
                dropdownMatchSelectWidth={false}
              />
            )}
          </div>
          <div className="switchHeader">
            {!path.pathname.includes(['categories']) && !path.pathname.includes('chart') ? (
              <div className="switch">
                <div className="syntheseSwitch">
                  <span>SYNTHESE</span>
                  <Switch onChange={onChangeSynthese} checked={checkedSynthese ? true : false} />
                </div>
                {objectives && objectives.length === 0 ? (
                  <div className="objectifSwitch" onClick={() => addObjectif()}>
                    <span>Créer un nouveau Objectif + </span>
                  </div>
                ) : (
                  objectives && (
                    <Select
                      className="selectObjective"
                      defaultValue={objectives[0]?.nameObjective}
                      style={{
                        width: 200
                      }}
                      onChange={changeObjective}
                      size={size}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: '8px 0'
                            }}
                          />
                          <Space
                            style={{
                              padding: '0 8px 4px'
                            }}>
                            <Input placeholder="Objectif" ref={inputRef} value={name} onChange={onNameChange} />
                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                              Ajouter
                            </Button>
                          </Space>
                        </>
                      )}>
                      {objectives?.map((item) => (
                        <Option key={item.id} value={item.id} label={item.nameObjective}>
                          {item.nameObjective}
                          <a
                            style={{ float: 'right' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteObjective(item.id); // pass the selected option to delete
                            }}>
                            <DeleteOutlined />
                          </a>
                        </Option>
                      ))}
                    </Select>
                  )
                )}
                {objectives && objectives.length > 0 && (
                  <div>
                    <Switch onChange={onChangeObjectif} />
                  </div>
                )}
              </div>
            ) : null}
          </div>
          {!path.pathname.includes(['categories']) && !path.pathname.includes(['chart']) && (
            <div>
              <img onClick={downloadExcel} src={exportTable} />
            </div>
          )}
        </div>
        {path.pathname.includes('chart') ? (
          <div className="header__right__mode">
            <div className="header__right__mode__treso">
              <Tooltip placement="bottomLeft" title={'Mode trésorerie'}>
                <img src={modeTreso} onClick={() => handleClickModeChart('treso')} />
              </Tooltip>
            </div>
            <div className="header__right__mode__compta">
              <Tooltip placement="bottomLeft" title={'Mode Comptabilité'}>
                <img src={modeCompta} onClick={() => handleClickModeChart('compta')} />
              </Tooltip>
            </div>
          </div>
        ) : path.pathname.includes('categories') ? (
          <div className="header__right__mode">
            <div className="header__right__mode__treso">
              <Tooltip placement="bottomLeft" title={'Mode trésorerie'}>
                <img src={modeTreso} onClick={() => handleClickModeElement('treso')} />
              </Tooltip>
            </div>
            <div className="header__right__mode__compta">
              <Tooltip placement="bottomLeft" title={'Mode Comptabilité'}>
                <img src={modeCompta} onClick={() => handleClickModeElement('compta')} />
              </Tooltip>
            </div>
          </div>
        ) : (
          <div className="header__right__mode">
            <div className="header__right__mode__treso">
              <Tooltip placement="bottomLeft" title={'Mode trésorerie'}>
                <Link to="/" onClick={() => handleClickMode('treso')}>
                  <img src={modeTreso} />
                </Link>
              </Tooltip>
            </div>
            <div className="header__right__mode__compta">
              <Tooltip placement="bottomLeft" title={'Mode Comptabilité'}>
                <Link to="/table-compta" onClick={() => handleClickMode('compta')}>
                  <img src={modeCompta} />
                </Link>
              </Tooltip>
            </div>
          </div>
        )}
        {!path.pathname.includes('chart') && (
          <div className="header_right_add-element">
            <Popover
              overlayClassName="popover-add-element"
              overlayStyle={{ paddingTop: '18px', paddingLeft: '6px' }}
              placement="bottomLeft"
              title={title}
              content={content}>
              <img src={add} />
            </Popover>
            {isModalVisible && !isModalOpen && (
              <PopupCustomer
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                typeEcritureDefault={typeEcritureDefault}
                valueStatut={valueStatut}
                sendDataElement={sendDataElement}
                mode={mode}
                dateActive={dateActive}
                months={months}
              />
            )}
          </div>
        )}

        <div className={active ? 'header__right__filterActive' : null} onClick={handleClickActive}>
          <img src={filter} />
          {active && !modeActive && (
            <div className="header__right__filterActive__modeFilterActiveTreso">
              <img src={prev} />
              <img src={engage} />
              <img src={pointe} />
            </div>
          )}
          {active && modeActive && (
            <div className="header__right__filterActive__modeFilterActiveCompta">
              <img src={simulation} />
              <img src={reel} />
            </div>
          )}
        </div>
        <div className="header__right__InputSearch">
          <InputSearch />
        </div>
        <div className="header__right__person">
          <img src={person} />
        </div>
      </div>
      {isModalOpen && (
        <Modal
          footer={[
            <div key="Submit" className="footer">
              <div key="keyConfirmation" className="btn_Confirmation">
                <Button key="annuler" onClick={() => handleCancel()} className="annuler">
                  Annuler
                </Button>
                <Button key="valider" className="valider" onClick={() => handleOk()}>
                  Valider
                </Button>
              </div>
            </div>
          ]}
          title="Terminez la création"
          open={isModalOpen}>
          <p>
            Il y a un élément dans la catégorie: <span>{nameCategory}</span> et dans la date: <span>{dateElement}</span> qui attend pour finir la création.
          </p>

          <p>Cliquez sur valider pour terminer la création ou cliquez sur Annuler pour supprimer l&apos;élément.</p>
        </Modal>
      )}
    </>
  );
};

export default Header;
